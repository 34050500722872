<template>
  <div class="app-container park-monthly-water">
    <el-row :gutter="10">
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择项目"
          v-model="searchForm.projectName"
          @change="projectChange"
        >
          <el-option
            v-for="item in projectOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择楼栋"
          filterable
          v-model="searchForm.build"
          @change="getFloorList"
          clearable
        >
          <el-option
            v-for="item in buildingOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择楼层"
          filterable
          v-model="searchForm.floor"
          @change="getRoomList"
          clearable
        >
          <el-option
            v-for="item in floorOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择房间"
          filterable
          v-model="searchForm.room"
          @change="roomChange"
          clearable
        >
          <el-option
            v-for="item in roomOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          v-model="selectData.deviceNames"
          multiple
          collapse-tags
          reserve-keyword
          placeholder="选择设备"
          :remote-method="remoteMethod"
          remote
          filterable
        >
          <el-checkbox
            style="padding-left: 20px; margin: 10px 0"
            v-model="deviceCheckedAll"
            @change="selectAll"
            >全选</el-checkbox
          >
          <el-option
            v-for="item in deviceNameOptions"
            :key="item.productKey + item.deviceName"
            :label="item.deviceName"
            :value="item.deviceName"
          >
            <span style="float: left">{{ item.deviceName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.deviceDescription
            }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-date-picker
          style="width: 100%"
          v-model="selectData.date"
          :clearable="false"
          type="month"
          placeholder="选择年月"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 10px">
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择类别"
          v-model="selectData.catelog"
          @change="tableLayout"
        >
          <el-option
            v-for="item in catelogOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-button type="primary" @click="getTableList"> 查询 </el-button>
        <el-button
          type="primary"
          @click="exportHandler"
          v-loading="exportLoading"
        >
          导出
        </el-button>
      </el-col>
    </el-row>
    <div class="table-container">
      <el-table
        :data="tableData"
        height="100%"
        border
        stripe
        ref="tableIns"
        :header-cell-style="
          () => {
            return 'background-color: #F0F4FA; color: #5D687C; ';
          }
        "
        style="width: 100%; margin-top: 20px"
        v-loading="tableLoading"
      >
        <el-table-column fixed align="center" type="index" width="50">
        </el-table-column>
        <el-table-column
          fixed
          align="center"
          prop="deviceName"
          label="表号"
          width="150"
        >
          <template slot-scope="scope">
            <span class="link-span" @click="jumpToDeviceShadow(scope.row)">
              {{ scope.row.deviceName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed align="center" prop="build" label="楼栋" />
        <el-table-column fixed align="center" prop="floor" label="楼层" />
        <el-table-column fixed align="center" prop="room" label="房间" />
        <el-table-column
          fixed
          align="center"
          prop="deviceDescription"
          show-overflow-tooltip
          label="位置描述"
        />
        <el-table-column
          fixed
          align="center"
          prop="catelog"
          label="类别"
          width="60"
        >
          <template>
            <div
              v-show="[1, 2].includes(selectData.catelog)"
              :style="{
                borderBottom:
                  selectData.catelog === 1 ? '1px solid #ebeef5' : 'none',
                margin: '0 -10px',
              }"
              class="top-part"
            >
              读数
            </div>
            <div
              class="bottom-part"
              v-show="[1, 3].includes(selectData.catelog)"
            >
              用量
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-for="(item, index) in columnsList"
          :label="`${index + 1}`"
          :key="index"
        >
          <template slot-scope="scope">
            <div
              v-show="[1, 2].includes(selectData.catelog)"
              :style="{
                borderBottom:
                  selectData.catelog === 1 ? '1px solid #ebeef5' : 'none',
                margin: '0 -10px',
              }"
              class="top-part"
            >
              {{
                scope.row.readValueList[index] === null
                  ? "-"
                  : scope.row.readValueList[index]
              }}
            </div>
            <div
              class="bottom-part"
              v-show="[1, 3].includes(selectData.catelog)"
            >
              {{
                scope.row.usedList[index] === null
                  ? "-"
                  : scope.row.usedList[index]
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          prop="totalUsed"
          v-if="[1, 3].includes(selectData.catelog)"
          label="总用量"
        />
      </el-table>
    </div>
    <div class="pagination-container">
      <pagination
        layout="sizes,total , prev, pager, next, jumper"
        :background="false"
        :total="total"
        :page.sync="searchForm.current"
        :limit.sync="searchForm.rowCount"
        @pagination="paginationChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getBuildingListByProjectName,
  getFloorListByBuild,
  getRoomListByFloor,
  parkWaterStatistics,
  getWaterElectricDeviceList,
} from "@/api/ruge/bmsNew/electricityMeter.js";
import { findDevicePage } from "@/api/ruge/vlink/device/device";
import Pagination from "@/components/Pagination";
import { downloadPromise } from "@/utils/download";
import { envInfo } from "@/constants/envInfo";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      exportLoading: false,
      deviceCheckedAll: false,
      tableLoading: false,
      loading: false,
      columnsList: [],
      tableData: [],
      deviceNameOptions: [],
      resourceOptions: [],
      projectOptions: [
        {
          value: "锦绣一期",
          label: "锦绣一期",
        },
        {
          value: "锦绣二期",
          label: "锦绣二期",
        },
        {
          value: "锦绣三期",
          label: "锦绣三期",
        },
      ],
      catelogOptions: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "读数",
        },
        {
          value: 3,
          label: "用量",
        },
      ],
      buildingOptions: [],
      floorOptions: [],
      roomOptions: [],
      selectData: {
        date: new Date(),
        catelog: 1, // 1-全部，2-读数，3-用量
        deviceNames: [],
      },
      searchForm: {
        projectName: "锦绣一期",
        build: "",
        floor: "",
        room: "",
        current: 1,
        rowCount: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getDeviceList();
    this.getBuildingList("锦绣一期");
  },
  methods: {
    jumpToDeviceShadow(row) {
      const url = `${location.origin}${location.pathname}#/iot/device/detail?productKey=${row.productKey}&deviceName=${row.deviceName}&activeTabName=shadow`;
      window.open(url, "_blank");
    },
    tableLayout() {
      this.$nextTick(() => {
        this.$refs.tableIns.doLayout();
      });
    },
    async exportHandler() {
      this.exportLoading = true;
      const chooseDate = new Date(this.selectData.date);
      const url = `${
        envInfo.bgApp.stationPath
      }/hydropower/waterStatisticExport?floor=${this.searchForm.floor}&room=${
        this.searchForm.room
      }&build=${this.searchForm.build}&projectName=${
        this.searchForm.projectName
      }&year=${chooseDate.getFullYear()}&month=${
        chooseDate.getMonth() + 1
      }&deviceNames=${this.selectData.deviceNames.join(",")}&type=${
        { 1: "", 2: "read", 3: "used" }[this.selectData.catelog]
      }`;
      await downloadPromise(url, "园区每月用水统计");
      this.exportLoading = false;
    },
    selectAll() {
      this.selectData.deviceNames = [];
      if (this.deviceCheckedAll) {
        this.deviceNameOptions.forEach((item) => {
          this.selectData.deviceNames.push(item.deviceName);
        });
      } else {
        this.selectData.deviceNames = [];
      }
      this.$emit("update:updateMultipleSelect", this.selectData.deviceNames);
    },
    getMonthDay(year, month) {
      let days = new Date(year, month, 0).getDate();
      let currentMonth = new Date().getMonth() + 1;
      let currentDay = new Date().getDate();
      this.columnsList = [];
      for (let i = 0; i < days; i++) {
        // 过滤当前月，当天日期后的天数
        // if (currentMonth === month && i >= currentDay) {
        //   return;
        // }
        this.columnsList.push(i);
      }
    },
    paginationChange(current) {
      this.searchForm.current = current.page;
      this.getTableList();
    },
    getTableList() {
      this.tableLoading = true;
      const chooseDate = new Date(this.selectData.date);
      parkWaterStatistics({
        ...this.searchForm,
        ...{
          year: chooseDate.getFullYear(),
          month: chooseDate.getMonth() + 1,
          deviceNames: this.selectData.deviceNames.join(","),
        },
      })
        .then((res) => {
          this.getMonthDay(chooseDate.getFullYear(), chooseDate.getMonth() + 1);
          this.tableData = res.rows;
          this.total = res.total;
        })
        .finally(() => {
          this.tableLoading = false;
          this.tableLayout();
        });
    },
    getRoomList(floor) {
      if (floor) {
        getRoomListByFloor({ floor, deviceType: "水表" }).then((res) => {
          this.roomOptions = res.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      } else {
        this.roomOptions = [];
      }
      this.searchForm.room = "";
      this.clearDeviceDatas();
    },
    roomChange() {
      this.clearDeviceDatas();
    },
    getFloorList(build) {
      if (build) {
        getFloorListByBuild({ build, deviceType: "水表" }).then((res) => {
          this.floorOptions = res.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      } else {
        this.floorOptions = [];
        this.roomOptions = [];
      }
      this.searchForm.floor = "";
      this.searchForm.room = "";
      this.clearDeviceDatas();
    },
    projectChange(projectName) {
      this.searchForm.build = "";
      this.searchForm.floor = "";
      this.searchForm.room = "";
      this.buildingOptions = [];
      this.floorOptions = [];
      this.roomOptions = [];
      this.getBuildingList(projectName);
      this.clearDeviceDatas();
    },
    clearDeviceDatas() {
      this.selectData.deviceNames = [];
      this.deviceCheckedAll = false;
      this.getDeviceList();
    },
    getBuildingList(projectName) {
      getBuildingListByProjectName({ projectName, deviceType: "水表" }).then(
        (res) => {
          this.buildingOptions = res.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        }
      );
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getDeviceList(query);
      } else {
        this.deviceNameOptions = this.resourceOptions;
      }
    },
    getDeviceList(filter) {
      getWaterElectricDeviceList({
        projectName: this.searchForm.projectName,
        build: this.searchForm.build,
        floor: this.searchForm.floor,
        room: this.searchForm.room,
        deviceName: filter,
        deviceType: "水表",
      }).then((res) => {
        if (!this.resourceOptions.length) {
          this.resourceOptions = res;
        }
        this.deviceNameOptions = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.park-monthly-water {
  .filter-line {
    .single-item {
      display: flex;
      align-items: center;
      .label {
        width: 120px;
        text-align: right;
      }
    }
  }
  .table-container {
    height: calc(100% - 170px);
    margin-top: 20px;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .top-part {
    padding-bottom: 10px;
  }
  .bottom-part {
    padding-top: 10px;
  }
  ::v-deep(.el-table th.el-table__cell > .cell) {
    font-weight: bold;
  }
  .pagination-container {
    margin-left: -10px;
  }
  .link-span {
    color: #1a4cec;
    cursor: pointer;
  }
}
</style>